import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-785dd34e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "container mt-5" }
const _hoisted_3 = { class: "columns is-tablet" }
const _hoisted_4 = { class: "column" }
const _hoisted_5 = { class: "transactions card custom-card custom-card-padding" }
const _hoisted_6 = {
  key: 0,
  class: "notification is-danger is-light"
}
const _hoisted_7 = { class: "mb-4" }
const _hoisted_8 = {
  key: 1,
  class: "notification is-default"
}
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "custom-card-title" }
const _hoisted_11 = { class: "table is-striped is-fullwidth" }
const _hoisted_12 = { class: "row-user-header" }
const _hoisted_13 = { class: "row-amount-header" }
const _hoisted_14 = { class: "row-amount-header" }
const _hoisted_15 = { class: "row-validate-header has-text-right" }
const _hoisted_16 = { class: "row-user" }
const _hoisted_17 = { class: "date" }
const _hoisted_18 = { class: "status relative-date mt-1" }
const _hoisted_19 = { class: "has-text-right" }
const _hoisted_20 = ["id", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.hasLoadingError)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$gettext(
                    "An unexpected issue occurred while loading the " +
                      "top up request list. Sorry for the inconvenience"
                  )), 1),
                  _createElementVNode("p", null, _toDisplayString(_ctx.$gettext(
                    "You can try to refresh the page, if the issue " +
                      "persists, you may want to contact your " +
                      "administrator"
                  )), 1)
                ]))
              : (_ctx.pendingCreditRequests.length === 0)
                ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$gettext("No top up request awaiting approval")), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("h2", _hoisted_10, _toDisplayString(_ctx.$gettext("Top up requests await approval")), 1),
                    _createElementVNode("table", _hoisted_11, [
                      _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", _hoisted_12, _toDisplayString(_ctx.$gettext("User")), 1),
                          _createElementVNode("th", _hoisted_13, _toDisplayString(_ctx.$gettext("Amount")), 1),
                          _createElementVNode("th", _hoisted_14, _toDisplayString(_ctx.$gettext("Date")), 1),
                          _createElementVNode("th", _hoisted_15, _toDisplayString(_ctx.$gettext("Approve")), 1)
                        ])
                      ]),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pendingCreditRequests, (request) => {
                          return (_openBlock(), _createElementBlock("tr", { key: request }, [
                            _createElementVNode("td", _hoisted_16, _toDisplayString(request.related) + " " + _toDisplayString(request.markBackend ? `(on ${request.backendId})` : ""), 1),
                            _createElementVNode("td", null, _toDisplayString(_ctx.numericFormat(parseFloat(request.amount))) + " " + _toDisplayString(request.currency), 1),
                            _createElementVNode("td", null, [
                              _createElementVNode("h5", _hoisted_17, _toDisplayString(_ctx.dateFormat(request.date)), 1),
                              _createElementVNode("h5", _hoisted_18, _toDisplayString(_ctx.relativeDateFormat(request.date)), 1)
                            ]),
                            _createElementVNode("td", _hoisted_19, [
                              _createElementVNode("a", {
                                class: "button is-primary custom-button custom-inverted is-small is-pulled-right",
                                id: `validate-${request.jsonData.odoo.credit_id}`,
                                onClick: ($event: any) => (_ctx.validateCreditRequest(request))
                              }, _toDisplayString(_ctx.$gettext("Approve")), 9, _hoisted_20)
                            ])
                          ]))
                        }), 128))
                      ])
                    ])
                  ]))
          ])
        ])
      ])
    ])
  ]))
}