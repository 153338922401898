import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-527308e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "transaction-list-recent" }
const _hoisted_2 = {
  key: 0,
  class: "section-card",
  id: "the-transaction-list"
}
const _hoisted_3 = { class: "custom-card-title" }
const _hoisted_4 = {
  key: 0,
  class: "notification is-danger is-light"
}
const _hoisted_5 = { class: "mb-4" }
const _hoisted_6 = { class: "mb-4" }
const _hoisted_7 = {
  key: 1,
  class: "notification is-default"
}
const _hoisted_8 = { key: 2 }
const _hoisted_9 = {
  key: 0,
  class: "has-text-centered mt-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransactionItem = _resolveComponent("TransactionItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasFinishedFirstLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$gettext("Transactions")), 1),
          (_ctx.isTransactionsLoadingError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$gettext(
              "An unexpected issue occurred while loading the last " +
                "transactions. Sorry for the inconvenience."
            )), 1),
                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$gettext(
              "You can try to refresh the page, if the issue persists, " +
                "you may want to contact your administrator"
            )), 1)
              ]))
            : (_ctx.transactions?.length === 0)
              ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$gettext("No previous transactions in your history.")), 1))
              : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.transactions, (transaction) => {
                    return (_openBlock(), _createBlock(_component_TransactionItem, {
                      key: transaction,
                      transaction: transaction,
                      onClick: ($event: any) => (
            _ctx.$modal.open('ConfirmPaymentModal', {
              transaction,
              type:
                transaction.amount < 0 &&
                _ctx.account.safeWalletRecipient?.name === transaction.related
                  ? 'reconversion'
                  : 'transactionDetail',
            })
          )
                    }, null, 8, ["transaction", "onClick"]))
                  }), 128)),
                  (_ctx.transactions.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("button", {
                          onClick: _cache[0] || (_cache[0] = 
              () => {
                _ctx.$modal.open('TransactionListModal', { account: _ctx.account })
              }
            ),
                          class: "button custom-button custom-inverted"
                        }, _toDisplayString(_ctx.$gettext("See more")), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]))
        ]))
      : _createCommentVNode("", true)
  ]))
}