import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, vModelText as _vModelText, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5827fdf0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main pb-4" }
const _hoisted_2 = {
  key: 0,
  class: "container"
}
const _hoisted_3 = { class: "columns mt-5" }
const _hoisted_4 = { class: "column" }
const _hoisted_5 = { class: "card custom-card-wallet" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "wallet-tab" }
const _hoisted_9 = { class: "container mt-5" }
const _hoisted_10 = { class: "columns" }
const _hoisted_11 = { class: "column" }
const _hoisted_12 = { class: "card custom-card" }
const _hoisted_13 = { class: "card-content" }
const _hoisted_14 = { class: "mb-3" }
const _hoisted_15 = { class: "notification is-danger" }
const _hoisted_16 = { class: "column" }
const _hoisted_17 = { class: "card custom-card" }
const _hoisted_18 = { class: "card-content" }
const _hoisted_19 = { class: "custom-card-title" }
const _hoisted_20 = { class: "field" }
const _hoisted_21 = { class: "label" }
const _hoisted_22 = { class: "control has-icons-left has-icons-right" }
const _hoisted_23 = {
  key: 0,
  class: "help is-danger"
}
const _hoisted_24 = { class: "field" }
const _hoisted_25 = { class: "label" }
const _hoisted_26 = { class: "control has-icons-left has-icons-right" }
const _hoisted_27 = ["placeholder", "disabled"]
const _hoisted_28 = { class: "icon is-small is-left" }
const _hoisted_29 = {
  key: 0,
  class: "icon is-small is-right"
}
const _hoisted_30 = {
  key: 0,
  class: "help is-danger"
}
const _hoisted_31 = {
  key: 0,
  class: "card-content"
}
const _hoisted_32 = { class: "field" }
const _hoisted_33 = { class: "control" }
const _hoisted_34 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PasswordField = _resolveComponent("PasswordField")!
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_AuthPref = _resolveComponent("AuthPref")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    (_ctx.getUnconfiguredBackends().length > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getUnconfiguredBackends(), (backend, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass([{
                      'is-wallet-active': _ctx.form.accountBackend === backend,
                    }, "button is-wallet has-text-weight-medium is-rounded ml-3"]),
                        onClick: ($event: any) => (_ctx.form.accountBackend = backend)
                      }, [
                        _createElementVNode("li", null, [
                          _createElementVNode("a", _hoisted_8, [
                            _createElementVNode("div", null, _toDisplayString(backend), 1)
                          ])
                        ])
                      ], 10, _hoisted_7))
                    }), 256))
                  ])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$gettext(
                    "Choose the password for your wallet. It is different " +
                      "from your user account password and is only related to " +
                      "your wallet."
                  )), 1),
              _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$gettext(
                    "Please note that this password can not be retrieved, " +
                      "so be sure to keep it in a safe and reachable place."
                  )), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("h2", _hoisted_19, _toDisplayString(_ctx.$gettext("Create my wallet")), 1),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$gettext("Password")), 1),
                _createElementVNode("div", _hoisted_22, [
                  _createVNode(_component_PasswordField, {
                    password: _ctx.form.accountPassword,
                    "onUpdate:password": _cache[0] || (_cache[0] = (x) => (_ctx.form.accountPassword = x)),
                    autocomplete: "new-password",
                    class: _normalizeClass({
                      'is-danger': _ctx.hasError('accountPassword'),
                      'is-success':
                        !_ctx.hasError('accountPassword') &&
                        _ctx.form.accountPassword.length > 1,
                    }),
                    disabled: _ctx.useSimplifiedAuth,
                    "icon-right": 
                      _ctx.hasError('accountPassword')
                        ? 'triangle-exclamation'
                        : null
                    
                  }, null, 8, ["password", "class", "disabled", "icon-right"])
                ]),
                (_ctx.hasError('accountPassword'))
                  ? (_openBlock(), _createElementBlock("p", _hoisted_23, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.errors.accountPassword, (err) => {
                        return (_openBlock(), _createElementBlock("div", null, _toDisplayString(err), 1))
                      }), 256))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$gettext("Password confirmation")), 1),
                _createElementVNode("div", _hoisted_26, [
                  _withDirectives(_createElementVNode("input", {
                    onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.createUserAccount && _ctx.createUserAccount(...args)), ["enter"])),
                    class: _normalizeClass(["input", {
                      'is-danger': _ctx.hasError('accountPasswordConfirm'),
                      'is-success':
                        !_ctx.hasError('accountPasswordConfirm') &&
                        _ctx.form.accountPasswordConfirm.length > 1,
                    }]),
                    autocomplete: "new-password",
                    type: "password",
                    placeholder: _ctx.$gettext('Password confirmation'),
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.accountPasswordConfirm) = $event)),
                    disabled: _ctx.useSimplifiedAuth
                  }, null, 42, _hoisted_27), [
                    [_vModelText, _ctx.form.accountPasswordConfirm]
                  ]),
                  _createElementVNode("span", _hoisted_28, [
                    _createVNode(_component_fa_icon, { icon: "key" })
                  ]),
                  (_ctx.hasError('accountPasswordConfirm'))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_29, [
                        _createVNode(_component_fa_icon, { icon: "triangle-exclamation" })
                      ]))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.hasError('accountPasswordConfirm'))
                  ? (_openBlock(), _createElementBlock("p", _hoisted_30, _toDisplayString(_ctx.form.errors.accountPasswordConfirm[0]), 1))
                  : _createCommentVNode("", true)
              ]),
              (!_ctx.hasFieldErrors)
                ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                    _createVNode(_component_AuthPref, {
                      handler: _ctx.handler,
                      requestCredentials: _ctx.requestCredentials,
                      disabled: _ctx.hasFieldErrors,
                      onSaveConfig: _ctx.saveSimplifiedAuthPref
                    }, null, 8, ["handler", "requestCredentials", "disabled", "onSaveConfig"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("div", _hoisted_33, [
                  _createElementVNode("button", {
                    class: "button is-primary",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.createUserAccount())),
                    disabled: _ctx.hasFieldErrors
                  }, _toDisplayString(_ctx.$gettext("Create my wallet")), 9, _hoisted_34)
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}