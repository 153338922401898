import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-582a11a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "dropdown"
}
const _hoisted_2 = { class: "dropdown-trigger" }
const _hoisted_3 = ["aria-controls"]
const _hoisted_4 = { class: "icon" }
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: "dropdown-content" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "mr-1 icon-container" }
const _hoisted_9 = { class: "is-small ml-1" }
const _hoisted_10 = { class: "icon" }
const _hoisted_11 = {
  key: 2,
  class: "button is-default button-contextual-menu is-pulled-right is-rounded ml-2 hide"
}
const _hoisted_12 = { class: "icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_ctx.$dropdownMenu.listItems(_ctx.object).length > 1)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", {
            class: "button is-default button-contextual-menu is-pulled-right is-rounded refresh ml-2",
            "aria-haspopup": "true",
            "aria-controls": `dropdown-${_ctx.object.backend}-menu`
          }, [
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_fa_icon, {
                class: "qrcode-icon",
                icon: "ellipsis-v"
              })
            ])
          ], 8, _hoisted_3)
        ]),
        _createElementVNode("div", {
          class: "dropdown-menu",
          id: `dropdown-${_ctx.object.backend}-menu`,
          role: "menu"
        }, [
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$dropdownMenu.listItems(_ctx.object), (item) => {
              return (_openBlock(), _createElementBlock("a", {
                key: item.label,
                href: "#",
                class: "dropdown-item is-flex",
                onClick: ($event: any) => (item.action(this))
              }, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_fa_icon, {
                    icon: item.icon
                  }, null, 8, ["icon"])
                ]),
                _createElementVNode("div", _hoisted_9, _toDisplayString(item.label), 1)
              ], 8, _hoisted_7))
            }), 128))
          ])
        ], 8, _hoisted_5)
      ]))
    : (_ctx.$dropdownMenu.listItems(_ctx.object).length === 1)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: "button is-default button-contextual-menu is-pulled-right is-rounded refresh ml-2",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$dropdownMenu.listItems(_ctx.object)[0].action(this)))
        }, [
          _createElementVNode("span", _hoisted_10, [
            _createVNode(_component_fa_icon, {
              class: "qrcode-icon",
              icon: _ctx.$dropdownMenu.listItems(_ctx.object)[0].icon
            }, null, 8, ["icon"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("span", _hoisted_11, [
          _createElementVNode("span", _hoisted_12, [
            _createVNode(_component_fa_icon, {
              class: "qrcode-icon",
              icon: "ellipsis-v"
            })
          ])
        ]))
}