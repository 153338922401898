import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-057c508d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-inner-card card px-5 py-2 is-flex" }
const _hoisted_2 = { class: "is-size-5 is-flex-grow-1" }
const _hoisted_3 = {
  key: 0,
  class: "account-backend is-size-6 error-msg"
}
const _hoisted_4 = {
  key: 1,
  class: "account-backend is-size-6"
}
const _hoisted_5 = { class: "is-align-items-center is-flex" }
const _hoisted_6 = {
  key: 0,
  class: "is-size-6-mobile is-size-4-tablet account-bal"
}
const _hoisted_7 = {
  key: 1,
  class: "is-size-6-mobile is-size-4-tablet account-bal inactive"
}
const _hoisted_8 = { class: "is-size-6-mobile is-size-5-tablet account-curr" }
const _hoisted_9 = {
  key: 0,
  class: "sub-accounts"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!
  const _component_BankAccountItem = _resolveComponent("BankAccountItem", true)!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["account", { active: _ctx.account.active }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isSub || _ctx.isAccountSelected || _ctx.$emit('accountSelected', _ctx.account)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "name", {}, () => [
          _createTextVNode("default name")
        ], true),
        (_ctx.isTemporarilyUnavailable)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$gettext("Temporarily unavailable - please refresh")), 1))
          : _createCommentVNode("", true),
        (_ctx.isMultiCurrency && !_ctx.isSub)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.account?.backend), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.account?.active)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.numericFormat(parseFloat(_ctx.account?.bal))), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_7, "-.---,--")),
        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.account?.curr || "--"), 1),
        _createElementVNode("span", {
          class: _normalizeClass({
            hide: !_ctx.isAccountSelected || _ctx.isSub || !_ctx.showSubAccounts,
          })
        }, [
          _createVNode(_component_DropdownMenu, {
            object: _ctx.account,
            onRefreshTransaction: _ctx.refreshTransaction,
            onRefreshAccounts: _ctx.refreshAccounts
          }, null, 8, ["object", "onRefreshTransaction", "onRefreshAccounts"])
        ], 2)
      ])
    ]),
    (
        _ctx.account?.subAccounts &&
        _ctx.account.subAccounts.length > 0 &&
        _ctx.showSubAccounts
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.account.subAccounts, (account) => {
            return (_openBlock(), _createBlock(_component_BankAccountItem, {
              isSub: true,
              accountSelected: "false",
              class: "mt-4 subaccount",
              onAccountSelected: ($event: any) => (_ctx.$emit('accountSelected', account)),
              account: account
            }, {
              name: _withCtx(() => [
                _createTextVNode(_toDisplayString(account.name()), 1)
              ]),
              _: 2
            }, 1032, ["onAccountSelected", "account"]))
          }), 256))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}