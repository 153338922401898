import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74cb204a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "container mt-5" }
const _hoisted_3 = { class: "columns is-tablet" }
const _hoisted_4 = { class: "column" }
const _hoisted_5 = { class: "accounts card custom-card custom-card-padding" }
const _hoisted_6 = {
  key: 0,
  class: "notification is-danger is-light"
}
const _hoisted_7 = { class: "mb-4" }
const _hoisted_8 = {
  key: 1,
  class: "notification is-default"
}
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "custom-card-title" }
const _hoisted_11 = { class: "table is-striped is-fullwidth" }
const _hoisted_12 = { class: "row-user-header" }
const _hoisted_13 = { class: "has-text-right row-validate-header" }
const _hoisted_14 = { class: "row-user" }
const _hoisted_15 = { class: "has-text-right" }
const _hoisted_16 = ["id", "onClick"]
const _hoisted_17 = ["id", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, [
              (_ctx.hasLoadingError)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$gettext(
                      "An unexpected issue occurred while loading the " +
                        "pending account list. Sorry for the inconvenience."
                    )), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$gettext(
                      "You can try to refresh the page, if the issue " +
                        "persists, you may want to contact your " +
                        "administrator"
                    )), 1)
                  ]))
                : (_ctx.pendingUserAccounts.length === 0)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$gettext("No account pending for approval")), 1))
                  : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("h2", _hoisted_10, _toDisplayString(_ctx.$gettext("Accounts waiting for approval")), 1),
                      _createElementVNode("table", _hoisted_11, [
                        _createElementVNode("thead", null, [
                          _createElementVNode("tr", null, [
                            _createElementVNode("th", _hoisted_12, _toDisplayString(_ctx.$gettext("User")), 1),
                            _createElementVNode("th", _hoisted_13, _toDisplayString(_ctx.$gettext("Action")), 1)
                          ])
                        ]),
                        _createElementVNode("tbody", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pendingUserAccounts, (account) => {
                            return (_openBlock(), _createElementBlock("tr", {
                              key: account.id
                            }, [
                              _createElementVNode("td", _hoisted_14, _toDisplayString(account.name) + " " + _toDisplayString(account.markBackend
                            ? `(via ${account.backendId})`
                            : ""), 1),
                              _createElementVNode("td", _hoisted_15, [
                                _createElementVNode("a", {
                                  class: "button is-primary custom-button custom-inverted is-small is-pulled-right ml-2 mb-1 discard-account",
                                  id: `discard-${account.id}`,
                                  onClick: ($event: any) => (_ctx.discardUserAccount(account))
                                }, _toDisplayString(_ctx.$gettext("Discard")), 9, _hoisted_16),
                                _createElementVNode("a", {
                                  class: "button is-primary custom-button custom-inverted is-small is-pulled-right validate-account",
                                  id: `validate-${account.id}`,
                                  onClick: ($event: any) => (_ctx.validateUserAccount(account))
                                }, _toDisplayString(_ctx.$gettext("Approve")), 9, _hoisted_17)
                              ])
                            ]))
                          }), 128))
                        ])
                      ])
                    ]))
            ])
          ])
        ])
      ])
    ])
  ]))
}